<template>
	<div class="tcontainer">

		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<div class="title">Manage Radiologists</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">
			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<Column field="name" header="Name" filterField="name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<b>{{ data['name'] }}</b>
					</div>
				</template>
			</Column>
			<Column field="degree" header="Degree" filterField="degree" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Degree" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['degree'] }}
					</div>
				</template>
			</Column>
			<Column field="email" header="Email" filterField="email" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Email" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['email'] }}
					</div>
				</template>
			</Column>
			<Column field="phone" header="Phone" filterField="phone" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Phone" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['phone'] }}
					</div>
				</template>
			</Column>
			
			
			
			<Column field="is_active" header="Is Active" filterField="is_active" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Is Active</option>

							<option value="y">Y</option>
							<option value="n">N</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['is_active'] }}
					</div>
				</template>
			</Column>
			
			<Column field="due_amount" header="Due Amount" filterField="due_amount" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Due Amount" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						&#8377;{{ data['due_amount'] }}
					</div>
				</template>
			</Column>
			<Column field="last_month_dues" header="Last Month Dues" filterField="last_month_dues" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Last Month Dues" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata text-center">
						<div>
							&#8377;{{ data['last_month_dues'] }}
						</div>
						<!-- <div v-if="data['last_month_dues'] && data.last_month_paid == 'n'">
							<i @click="openPaymentPopup(data)" style="font-size: 30px; cursor: pointer;" class="fa-solid fa-money-check-dollar text-success"></i>
						</div> -->
					</div>
				</template>
			</Column>
			<Column
				header="Options &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;">
				<template #body="{ data }">
					<div class="tdata options">
						<button class="btn btn-sm btn-outline-success" @click="login(data)"><i class="fa fa-unlock-alt"></i></button>
						<router-link :to="'radiologist/payment/' + data['id']">
							<button class="btn btn-sm btn-outline-primary"><i class="fa fa-money"></i></button>
						</router-link>
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)"><i
								class="fa fa-edit"></i></button>
						<!-- <button class="btn btn-sm btn-outline-info" @click="showAssign(data)"><i
								class="fa fa-flag"></i></button> -->
						<button class="btn btn-sm btn-outline-danger" @click="editId = data['id']; deleteModal.show();"><i
								class="fa fa-trash"></i></button>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="modal fade" ref="assignModal" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Assign Center</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">

						<PickList v-model="fdata.center_ids" dataKey="id">
							<template #sourceHeader>
								Available
							</template>
							<template #targetHeader>
								Selected
							</template>
							<template #item="slotProps">
								<div>
									<div>
										{{ slotProps.item.name }}
									</div>
								</div>
							</template>
						</PickList>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-primary" @click="saveAssign">Save</button>
					</div>
				</div>
			</div>
		</div> -->


		<!-- <div class="modal fade" ref="payModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Make Payment To {{ editRow?.name }}</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">

						<div class="form-group">
							<label>Amount</label>
							<input type="number" v-model="pay.amount" class="form-control">
						</div>
						<div class="form-group">
							<label>Mode</label>
							<select v-model="pay.mode" class="form-control">
								<option value="" disabled>Select Payemnt Mode</option>
								<option>NEFT</option>
								<option>Cash</option>
								<option>Paytm</option>
								<option>GPay</option>
								<option>PhonePe</option>
								<option>UPI</option>
							</select>
						</div>
						<div class="form-group">
							<label>Transaction ID</label>
							<input type="text" v-model="pay.transaction_id" class="form-control">
						</div>
						<div class="form-group p-fluid">
							<label>Payment Date</label>
							<Calendar placeholder="Payment Date" v-model="pay.created_at" dateFormat='dd/mm/yy' />
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-info" @click="makePayment">Confirm Payment</button>
					</div>
				</div>
			</div>
		</div> -->

		<form action="" @submit.prevent="putRecord" ref="addForm">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Radiologist</div>
								<div v-else>Edit Radiologist</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.name?.hasError && fcontrol.name.touched }"
												v-model="fdata.name" @blur="fcontrol.name.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.name?.hasError">
												<span v-if="fcontrol.name.errors.required">You must enter name</span>
												<span v-if="fcontrol.name.errors.max">Name can contain maximum
													{{ fcontrol.name.errors.max.maxLength }} characters</span>
											</div>
											<label>Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.email?.hasError && fcontrol.email.touched }"
												v-model="fdata.email" @blur="fcontrol.email.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.email?.hasError">
												<span v-if="fcontrol.email.errors.required">You must enter email</span>
												<span v-if="fcontrol.email.errors.max">Email can contain maximum
													{{ fcontrol.email.errors.max.maxLength }} characters</span>
											</div>
											<label>Email</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.password?.hasError && fcontrol.password.touched }"
												v-model="fdata.password" @blur="fcontrol.password.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.password?.hasError">
												<span v-if="fcontrol.password.errors.required">You must enter password</span>
												<span v-if="fcontrol.password.errors.max">Password can contain maximum
													{{ fcontrol.password.errors.max.maxLength }} characters</span>
											</div>
											<label>Password</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.phone?.hasError && fcontrol.phone.touched }"
												v-model="fdata.phone" @blur="fcontrol.phone.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.phone?.hasError">
												<span v-if="fcontrol.phone.errors.required">You must enter phone</span>
												<span v-if="fcontrol.phone.errors.max">Phone can contain maximum
													{{ fcontrol.phone.errors.max.maxLength }} characters</span>
											</div>
											<label>Phone</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.address?.hasError && fcontrol.address.touched }"
												v-model="fdata.address" @blur="fcontrol.address.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.address?.hasError">
												<span v-if="fcontrol.address.errors.required">You must enter address</span>
												<span v-if="fcontrol.address.errors.max">Address can contain maximum
													{{ fcontrol.address.errors.max.maxLength }} characters</span>
											</div>
											<label>Address</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.specialization?.hasError && fcontrol.specialization.touched }"
												v-model="fdata.specialization" @blur="fcontrol.specialization.touched = true">
												<option value="" disabled selected>Select Specialization</option>
												<option value="radiology">Radiology</option>
												<option value="radiology_special">Radiology Special</option>
												<option value="opg">OPG</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.specialization?.hasError">
												<span v-if="fcontrol.specialization.errors.required">You must select specialization</span>
											</div>
											<label>Specialization</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" readonly class="form-control"
												:class="{ 'is-invalid': fcontrol.joining_date?.hasError && fcontrol.joining_date.touched }"
												v-model="fdata.joining_date" @blur="fcontrol.joining_date.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.joining_date?.hasError">
												<span v-if="fcontrol.joining_date.errors.required">You must enter joining
													date</span>
											</div>
											<label>Joining Date</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.registration_no?.hasError && fcontrol.registration_no.touched }"
												v-model="fdata.registration_no" @blur="fcontrol.registration_no.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.registration_no?.hasError">
												<span v-if="fcontrol.registration_no.errors.required">You must enter registration
													no</span>
												<span v-if="fcontrol.registration_no.errors.max">Registration No can contain maximum
													{{ fcontrol.registration_no.errors.max.maxLength }} characters</span>
											</div>
											<label>Registration No</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.degree?.hasError && fcontrol.degree.touched }"
												v-model="fdata.degree" @blur="fcontrol.degree.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.degree?.hasError">
												<span v-if="fcontrol.degree.errors.required">You must enter degree</span>
												<span v-if="fcontrol.degree.errors.max">Degree can contain maximum
													{{ fcontrol.degree.errors.max.maxLength }} characters</span>
											</div>
											<label>Degree</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="number" class="form-control"
												:class="{ 'is-invalid': fcontrol.accept_report_limit?.hasError && fcontrol.accept_report_limit.touched }"
												v-model="fdata.accept_report_limit" @blur="fcontrol.accept_report_limit.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.accept_report_limit?.hasError">
												<span v-if="fcontrol.accept_report_limit.errors.required">You must enter accept report limit</span>
											</div>
											<label>Accept Report Limit</label>
										</div>

										<div class="form-group ui-float-label">
											<input type="number" step="any" class="form-control"
												:class="{ 'is-invalid': fcontrol.waiting_factor?.hasError && fcontrol.waiting_factor.touched }"
												v-model="fdata.waiting_factor" @blur="fcontrol.waiting_factor.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.waiting_factor?.hasError">
												<span v-if="fcontrol.waiting_factor.errors.required">You must enter waiting factor</span>
											</div>
											<label>Waiting Factor</label>
										</div>
										<!-- <div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.time_restriction?.hasError && fcontrol.time_restriction.touched }"
												v-model="fdata.time_restriction" @blur="fcontrol.time_restriction.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.time_restriction?.hasError">
												<span v-if="fcontrol.time_restriction.errors.required">You must enter time restriction</span>
											</div>
											<label>Time Restriction</label>
										</div> -->
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.is_active?.hasError && fcontrol.is_active.touched }"
												v-model="fdata.is_active" @blur="fcontrol.is_active.touched = true">
												<option value="" disabled selected>Select Is Active</option>
												<option value="y">Y</option>
												<option value="n">N</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.is_active?.hasError">
												<span v-if="fcontrol.is_active.errors.required">You must enter is active</span>
												<span v-if="fcontrol.is_active.errors.in">Invalid is active</span>
											</div>
											<label>Is Active</label>
										</div>
										<!-- <div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.is_online?.hasError && fcontrol.is_online.touched }"
												v-model="fdata.is_online" @blur="fcontrol.is_online.touched = true">
												<option value="" disabled selected>Select Is Online</option>
												<option value="y">Y</option>
												<option value="n">N</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.is_online?.hasError">
												<span v-if="fcontrol.is_online.errors.required">You must enter is online</span>
												<span v-if="fcontrol.is_online.errors.in">Invalid is online</span>
											</div>
											<label>Is Online</label>
										</div> -->
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.is_present?.hasError && fcontrol.is_present.touched }"
												v-model="fdata.is_present" @blur="fcontrol.is_present.touched = true">
												<option value="" disabled selected>Select Is Present</option>
												<option value="y">Y</option>
												<option value="n">N</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.is_present?.hasError">
												<span v-if="fcontrol.is_present.errors.required">You must enter is present</span>
												<span v-if="fcontrol.is_present.errors.in">Invalid is present</span>
											</div>
											<label>Is Present</label>
										</div>

										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.disable_direct_report?.hasError && fcontrol.disable_direct_report.touched }"
												v-model="fdata.disable_direct_report" @blur="fcontrol.disable_direct_report.touched = true">
												<option value="" disabled selected>Select Disable Direct Report</option>
												<option value="y">Y</option>
												<option value="n">N</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.disable_direct_report?.hasError">
												<span v-if="fcontrol.disable_direct_report.errors.required">You must select disable direct report</span>
												<span v-if="fcontrol.disable_direct_report.errors.in">Invalid disable report</span>
											</div>
											<label>Disable Direct Report</label>
										</div>

										

										<!-- <div class="form-group">
											<span class="p-fluid p-float-label">
												<AutoComplete forceSelection :multiple="true" v-model="selectedDoctors"
													:suggestions="filteredDoctors" @complete="searchDoctor($event)" field="name" />
												<label>Selected Centers</label>
											</span>
										</div> -->

										<div class="form-group ui-float-label">
											<textarea class="form-control"
												:class="{ 'is-invalid': fcontrol.bank_details?.hasError && fcontrol.bank_details.touched }"
												v-model="fdata.bank_details" @blur="fcontrol.bank_details.touched = true"
												placeholder=" "></textarea>
											<div class="invalid-feedback" v-if="fcontrol.bank_details?.hasError">
												<span v-if="fcontrol.bank_details.errors.required">You must enter bank details</span>
											</div>
											<label>Bank Details</label>
										</div>


									</div>
									<div class="col-sm-6">

										<div class="form-group ui-float-label">
											<input type="file" accept="image/*" class="form-control" @change="selectImage(1)"
												ref="certificate1" />
											<label>Certificate Image 1</label>
										</div>
										<div v-if="certificate1Link" style="margin-bottom: 15px;">
											<img style="max-width: 100%; max-height: 300px; display: block; margin: auto;"
												:src="certificate1Link" alt="">
										</div>
										<div class="form-group ui-float-label">
											<input type="file" accept="image/*" class="form-control" @change="selectImage(2)"
												ref="certificate2" />
											<label>Certificate Image 2</label>
										</div>
										<div v-if="certificate2Link" style="margin-bottom: 15px;">
											<img style="max-width: 100%; max-height: 300px; display: block; margin: auto;"
												:src="certificate2Link" alt="">
										</div>

										<div class="form-group ui-float-label">
											<input type="file" accept="image/*" class="form-control" @change="selectSignature(3)"
												ref="signature" />
											<label>Signature Image</label>
										</div>
										<div v-if="signatureLink" style="margin-bottom: 15px;">
											<img style="max-width: 100%; max-height: 300px; display: block; margin: auto;"
												:src="signatureLink" alt="">
										</div>


									</div>
								</div>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import { Modal } from 'bootstrap'
// import PickList from 'primevue/picklist';
// import Calendar from 'primevue/calendar';

export default {
	components: {
		// PickList,
		// Calendar,
	},
	data() {
		return {

			// pay: {
			// 	doctor_id: 0,
			// 	amount: '',
			// 	mode: '',
			// 	transaction_id: '',
			// 	created_at: null,
			// },

			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: null,
			certificate_file_1: null,
			certificate_file_2: null,
			certificate_image_1: null,
			certificate_image_2: null,
			signature_file: null,
			signature_image: null,
			saving: false,
			centers: [],
			filteredDoctors: null,
			selectedDoctors: [],
			addModal: null,
			deleteModal: null,
			// assignModal: null,
			// payModal: null,
			lazyParams: {},
			filters: {
				'id': { value: '', matchMode: 'like' },
				'name': { value: '', matchMode: 'like' },
				'email': { value: '', matchMode: 'like' },
				'password': { value: '', matchMode: 'like' },
				'phone': { value: '', matchMode: 'like' },
				'address': { value: '', matchMode: 'like' },
				'joining_date': { value: '', matchMode: 'like' },
				'registration_no': { value: '', matchMode: 'like' },
				'degree': { value: '', matchMode: 'like' },
				'certificate_image_1': { value: '', matchMode: 'like' },
				'certificate_image_2': { value: '', matchMode: 'like' },
				'is_active': { value: '', matchMode: 'like' },
				'center_ids': { value: '', matchMode: 'like' },
				'due_amount': { value: '', matchMode: 'like' },
				'last_month_dues': { value: '', matchMode: 'like' },
			},
			fdata: {
				name: '',
				email: '',
				password: '',
				phone: '',
				address: '',
				specialization: '',
				joining_date: '',
				registration_no: '',
				degree: '',
				accept_report_limit: '',
				waiting_factor: '',
				// time_restriction: '',
				is_active: 'y',
				// is_online: 'y',
				is_present: 'y',
				disable_direct_report: 'n',
				// center_ids: [],
				bank_details: '',
			},
			fvalidator: {
				name: 'required|max:50',
				email: 'required|max:50',
				password: 'required|max:50',
				phone: 'required|max:20',
				address: 'required|max:255',
				specialization: 'required',
				joining_date: '',
				registration_no: 'required|max:100',
				degree: 'required|max:20',
				accept_report_limit: 'required',
				waiting_factor: 'required',
				// time_restriction: '',
				is_active: 'required|in:y,n',
				// is_online: 'required|in:y,n',
				is_present: 'required|in:y,n',
				disable_direct_report: 'required|in:y,n',
				// center_ids: '',
				bank_details: 'required',
			}
		}
	},
	computed: {
		certificate1Link() {
			let link = null;
			if (this.certificate_image_1) link = this.certificate_image_1;
			else if (this.editRow?.certificate_image_1) link = this.apiOrigin + '/../images/radiologist_certificates/' + this.editRow?.certificate_image_1;
			return link;
		},
		certificate2Link() {
			let link = null;
			if (this.certificate_image_2) link = this.certificate_image_2;
			else if (this.editRow?.certificate_image_2) link = this.apiOrigin + '/../images/radiologist_certificates/' + this.editRow?.certificate_image_2;
			return link;
		},
		signatureLink() {
			let link = null;
			if (this.signature_image) link = this.signature_image;
			else if (this.editRow?.signature_image) link = this.apiOrigin + '/../images/radiologist_certificates/' + this.editRow?.signature_image;
			return link;
		},

	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.deleteModal = new Modal(this.$refs.deleteModal);
		// this.assignModal = new Modal(this.$refs.assignModal);
		// this.payModal = new Modal(this.$refs.payModal);


		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		

		// this.post("admin/load-centers", {
		// 	first: 0,
		// 	rows: 1000000,
		// 	sortField: "id",
		// 	sortOrder: -1,
		// }).then(res => {
		// 	if (res.success) {
		// 		this.centers = res.rows;
		// 	}
		// });


		// this.post("admin/calculate-radiologist-last-month-due", {

		// }).then(res=>{
		// 	this.loadLazyData();
		// });
		
		this.loadLazyData();
	},
	methods: {
		// openPaymentPopup(data){
		// 	this.editRow=data;

		// 	this.pay.doctor_id = data.id,
		// 	this.pay.amount = data.last_month_dues;
		// 	this.pay.mode = '';
		// 	this.pay.transaction_id = '';
		// 	this.pay.created_at = new Date();

		// 	this.payModal.show();
		// },
		// makePayment(){
		// 	if(this.saving) return;
		// 	if(!this.pay.amount) return;
		// 	let fd = {
		// 		doctor_id: this.pay.doctor_id,
		// 		amount: this.pay.amount,
		// 		mode: this.pay.mode,
		// 		transaction_id: this.pay.transaction_id,
		// 		created_at: this.format(this.pay.created_at, "YYYY-MM-DD HH:mm:ss")
		// 	}
		// 	this.saving = true;

		// 	this.post("admin/make-radiologist-monthly-payment", fd).then(res => {
		// 		this.saving = false;
		// 		if(res.success){
		// 			this.loadLazyData();
		// 			this.payModal.hide();
		// 		}
		// 	});
		// },

		login(data){
			localStorage.radiologist_access_token = JSON.stringify(data);
			window.open('/radiologist/dashboard', "_blank").focus();
		},
		selectImage(num) {
			let img = this.$refs['certificate' + num].files[0];

			if (img) {
				var reader = new FileReader();
				reader.onload = e => {
					this['certificate_image_' + num] = e.target.result;
					this['certificate_file_' + num] = img;
				}
				reader.readAsDataURL(img);
			} else {
				this['certificate_image_' + num] = null;
				this['certificate_file_' + num] = null;
			}
		},
		selectSignature() {
			let img = this.$refs['signature'].files[0];

			if (img) {
				var reader = new FileReader();
				reader.onload = e => {
					this['signature_image'] = e.target.result;
					this['signature_file'] = img;
				}
				reader.readAsDataURL(img);
			} else {
				this['signature_image'] = null;
				this['signature_file'] = null;
			}
		},
		putRecord() {
			if (this.fHasError() || this.saving) return;
			this.saving = true;

			let data = JSON.parse(JSON.stringify(this.fdata));

			if (this.editId) data.id = this.editId;
			if (this.certificate_file_1) data.certificate_image_1 = this.certificate_file_1;
			if (this.certificate_file_2) data.certificate_image_2 = this.certificate_file_2;
			if (this.signature_file) data.signature_image = this.signature_file;


			// data.center_ids = this.selectedDoctors.map(d => d.id).join(",");

			this.upload("admin/put-radiologist", data, (err, res) => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				}
			});
		},
		// searchDoctor(event) {
		// 	setTimeout(() => {
		// 		if (!event.query.trim().length) {
		// 			this.filteredDoctors = [...this.centers];
		// 		} else {
		// 			this.filteredDoctors = this.centers.filter((d) => {
		// 				return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
		// 				// return country.name.toLowerCase().startsWith(event.query.toLowerCase());
		// 			});
		// 		}
		// 	}, 250);
		// },
		// showAssign(row) {
		// 	this.$refs['addForm'].reset();
		// 	this.freset();
		// 	this.editId = row ? row.id : 0;
		// 	this.editRow = row ? row : null;
		// 	if (row) this.fpopulate(row);

		// 	this.fdata.center_ids = [[], []];

		// 	let selectedCenters = row.center_ids ? row.center_ids.split(",") : [];
		// 	for (let c of this.centers) {
		// 		if (selectedCenters.includes(String(c.id))) {
		// 			this.fdata.center_ids[1].push(c)
		// 		} else {
		// 			this.fdata.center_ids[0].push(c)
		// 		}
		// 	}

		// 	this.assignModal.show();
		// },
		// saveAssign() {
		// 	if (this.saving) return;
		// 	this.saving = true;

		// 	let data = JSON.parse(JSON.stringify(this.fdata));

		// 	data.id = this.editId;

		// 	data.center_ids = data.center_ids[1].map(d => d.id).join(",");

		// 	this.upload("admin/put-radiologist", data, (err, res) => {
		// 		this.saving = false;
		// 		if (res.success) {
		// 			this.assignModal.hide();
		// 			this.loadLazyData();
		// 		}
		// 	});
		// },

		showAddEdit(row) {
			this.$refs['addForm'].reset();
			this.freset();
			this.editId = row ? row.id : 0;
			this.editRow = row ? row : null;
			if (row) this.fpopulate(row);

			// this.selectedDoctors = [];
			// if (row) {
			// 	let doctorIds = row.center_ids ? row.center_ids.split(",") : [];
			// 	this.selectedDoctors = this.centers.filter((d) => {
			// 		return doctorIds.includes(String(d.id));
			// 	});
			// }

			this.addModal.show();
		},
		deleteRecord() {
			this.delete("admin/delete-radiologist/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("admin/load-radiologists", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			// this.lazyParams = event;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>
</style>